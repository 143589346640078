import React from 'react';

/**************** SALES BI Tool *************************************************/
const BIHourlySales = React.lazy(() => import('../../bi/sales/HourlySales'));
const BIDailySalesDiscounts = React.lazy(() => import('../../bi/sales/DailySalesDiscounts'));
const BIDailySalesRefunds = React.lazy(() => import('../../bi/sales/DailySalesRefunds'));
const BIDailySalesCharges = React.lazy(() => import('../../bi/sales/DailySalesChargesReport/DailySalesCharges'));


/**************** MENU BI Tool *************************************************/
const SalesByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/salesByMenuItem/SalesByMenuItem'));
const HourlySalesByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/hourlySalesByMenuItem/HourlySalesByMenuItem'));
const SalesByReportingTag = React.lazy(() => import('../../reports/accounts/sales/menuSales/salesByReportingTag/SalesByReportingTag'));
const HourlySalesByReportingTag = React.lazy(() => import('../../reports/accounts/sales/menuSales/hourlySalesByReportingTag/HourlySalesByReportingTag'));
const RefundsByMenuItem = React.lazy(() => import('../../reports/accounts/sales/menuSales/refundsByMenuItem/RefundsByMenuItem'));

/**************** Inventory BI Tool *************************************************/
const SalesByProductReport = React.lazy(() => import('../../bi/inventory/salesByProduct/SalesByProductReport'));
const CogsSummaryReport = React.lazy(() => import('../../bi/inventory/cogs/CogsSummaryReport'));
const DiscrepanciesReport = React.lazy(() => import('../../bi/inventory/discrepancies/DiscrepanciesReport'));
const WastagesReport = React.lazy(() => import('../../bi/inventory/wastagesReport/WastagesReport'));
const InventoryStatusReport = React.lazy(() => import('../../bi/inventory/inventoryStatus/InventoryStatusReport'));
const InventoryPurchaseReport = React.lazy(() => import('../../bi/inventory/purchaseReport/InventoryPurchaseReport'));


/**************** Payroll BI Tool *************************************************/
const BIDailyUpsellingPoints = React.lazy(() => import('../../bi/payroll/DailyUpsellingPoints'));
const BIDailyPayrollCosts = React.lazy(() => import('../../bi/payroll/DailyPayrollCosts/DailyPayrollCosts'));
const BIDailySalesByDriver = React.lazy(() => import('../../bi/payroll/DailySalesByDriver'));
const BIDailyStaffMeals = React.lazy(() => import('../../bi/payroll/DailyStaffMeals'));
const BIDailyGuestMeals = React.lazy(() => import('../../bi/payroll/DailyGuestMeals'));
const BIDailyFreebiesMeals = React.lazy(() => import('../../bi/payroll/DailyFreebiesMeals'));


/**************** Accounts BI Tool *************************************************/
const BIDailyVenueCommissions = React.lazy(() => import('../../bi/accounts/DailyVenueCommissions'));
const BIFixedCosts = React.lazy(() => import('../../bi/accounts/FixedCosts'));

/**************** General Tools *************************************************/
const BIPeriodicCostsSummaryReport = React.lazy(() => import('../../bi/general/costsSummaryReport/PeriodicCostSummaryReport'));


export function getBIComponent(componentKey)
{
    // componentKey -> componentId::componentName   (where componentId must exist, componentName is optional)

    let component;

    const componentId = componentKey.split("::")[0];

    switch (componentId) 
    {
        /**************** SALES BI Tool *************************************************/

        case "BI.Tools.Sales.HourlySales":
            component = <BIHourlySales componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailyDiscounts":
            component = <BIDailySalesDiscounts componentKey={componentKey} />
            break;

        case "BI.Tools.Sales.DailyRefunds":
            component = <BIDailySalesRefunds componentKey={componentKey} />
            break;
        
        case "BI.Tools.Sales.DailyCharges":
            component = <BIDailySalesCharges componentKey={componentKey} />
            break;

        /**************** MENU BI Tool *************************************************/

        case "BI.Tools.Menu.Item.Sales":
            component = <SalesByMenuItem componentKey={componentKey} />
            break;

        case "BI.Tools.Hourly.Menu.Item.Sales":
            component = <HourlySalesByMenuItem componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Daily.Tags.Sales":
            component = <SalesByReportingTag componentKey={componentKey} />
            break;

        case "BI.Tools.Menu.Hourly.Tags.Sales":
            component = <HourlySalesByReportingTag componentKey={componentKey} />
            break;
    
        case "BI.Tools.Menu.DailyMenuItemRefunds":
            component = <RefundsByMenuItem componentKey={componentKey} />
            break;

        /**************** INVENTORY BI Tool *************************************************/

        case "BI.Tools.Inventory.DailySalesByProduct":
            component = <SalesByProductReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.CogsSummary":
            component = <CogsSummaryReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.DailyDiscrepancies":
            component = <DiscrepanciesReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.WastagesReport":
            component = <WastagesReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.InventoryStatusReport":
            component = <InventoryStatusReport componentKey={componentKey} />
            break;

        case "BI.Tools.Inventory.InventoryPurchaseReport":
            component = <InventoryPurchaseReport componentKey={componentKey} />
            break;

        /**************** Payroll BI Tool *************************************************/

        case "BI.Tools.Payroll.DailySalesByDriver":
            component = <BIDailySalesByDriver componentKey={componentKey} />
            break;

        case "BI.Tools.Payroll.DailyStaffMeals":
            component = <BIDailyStaffMeals componentKey={componentKey} />
            break;

        case "BI.Tools.Payroll.DailyUpsellingPoints":
            component = <BIDailyUpsellingPoints componentKey={componentKey} />
            break;


        case "BI.Tools.Payroll.DailyPayrollCosts":
            component = <BIDailyPayrollCosts componentKey={componentKey} />
            break;

        /**************** Accounts BI Tool *************************************************/

        case "BI.Tools.Accounts.DailyVenueCommissions":
            component = <BIDailyVenueCommissions componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.BIFixedCosts":
            component = <BIFixedCosts componentKey={componentKey} />
            break;

        /**************** General BI Tool *************************************************/

        case "BI.Tools.Accounts.Costs.Periodic.Summary.Report":
            component = <BIPeriodicCostsSummaryReport componentKey={componentKey} />
            break;
        
        case "BI.Tools.Accounts.DailyGuestMeals":
            component = <BIDailyGuestMeals componentKey={componentKey} />
            break;

        case "BI.Tools.Accounts.DailyFreebiesMeals":
            component = <BIDailyFreebiesMeals componentKey={componentKey} />
            break;

        default:
            break;
    }

    return component;
}