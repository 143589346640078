import React from 'react';

// Non-BI based reports component helper
// Non-BI based reports componentName should be prefixed with "Reports." to leverage this component helper

// ****************************** Sales *******************************
const SalesOverviewAnalytics = React.lazy(() => import('../../reports/accounts/sales/salesAnalytics/SalesOverviewAnalytics'));
const SalesVatAnalytics = React.lazy(() => import('../../reports/accounts/sales/vat/SalesVatAnalytics'));
const SaleTipsAnalytics = React.lazy(() => import('../../reports/accounts/sales/tips/SaleTipsAnalytics'));
const SaleTimingAnalytics = React.lazy(() => import('../../reports/accounts/sales/timingAnalytics/SaleTimingAnalytics'));

const DailySummaryReport = React.lazy(() => import('../../reports/accounts/floatSessions/dailySummaryReport/DailySummaryReport'));
const DepositReturnReport = React.lazy(() => import('../../reports/accounts/floatSessions/depositTransactionReport/DepositTransactionReport'));

export function getReportComponent(componentName, componentParameter)
{
    let component;

    switch (componentName) 
    {
        // ****************************** Accounts.Sales *******************************
        case "Reports.Accounts.Sales.SalesOverviewAnalytics":
            component = <SalesOverviewAnalytics />
            break;
        case "Reports.Accounts.SalesVatAnalytics":
            component = <SalesVatAnalytics />
            break;
        case "Reports.Accounts.SalesTipsAnalytics":
            component = <SaleTipsAnalytics />
            break;
        case "Reports.Accounts.Sales.SaleTimingAnalytics":
            component = <SaleTimingAnalytics />
            break;
    
        // ****************************** Accounts.FloatSessions *******************************
        case "Reports.Accounts.FloatSessions.DailySummaryReport":
            component = <DailySummaryReport />
            break;

        case "Reports.Accounts.FloatSessions.DepositReturnReport":
            component = <DepositReturnReport/> 
            break;


        default:
            break;
    }

    return component;
}